<template>
    <r-e-dialog title="同住人" :visible.sync="dialogVisible" show-footer top="5vh" width="700px"
                @click-submit="handleSubmit" @click-cancel="handleCancel" @close="handleCancel">
        <el-form ref="formPublish" label-width="120px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="姓名" prop="name">
                    <el-input type="text" v-model="formPublish.name" :maxlength="6" placeholder="请填写同住人姓名"/>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                    <el-input type="text" v-model="formPublish.tel" :maxlength="11" placeholder="请填写同住人电话"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="身份证号" prop="idcard">
                    <el-input type="text" v-model="formPublish.idcard" :maxlength="18" placeholder="请填写身份证号"/>
                </el-form-item>
                <el-form-item label="与承租人关系" prop="relationship">
                    <el-select v-model="formPublish.relationship" placeholder="请选择">
                        <el-option v-for="item in relationshipList" :key="item.uuid" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {saveFamily, updateFamily} from "@/api/contract";
import {MessageError, MessageSuccess} from "@custom/message";

export default {
    name: "dialog-save-tongzhuren",
    props: {
        contractData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                name: "",
                tel: "",
                idcard: "",
                relationship: ""
            },
            rules: {
                name: [{required: true, message: '请输入同住人姓名', trigger: 'blur'},],
                tel: [{required: true, message: '请输入同住人电话', trigger: 'blur'},],
                idcard: [{required: true, message: '请输入同住人身份证号', trigger: 'blur'},],
                relationship: [{required: true, message: '请选择与承租人关系', trigger: 'change'}]
            },
            relationshipList: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    async created() {
        //获取同住人类型数组
        this.relationshipList = await this.$store.dispatch('app/getDictionaryByCodeActions', '600700');
    },
    methods: {
        //打开添加同住人弹框方法
        openDialog(data) {
            // 打开打开添加同住人弹框
            this.dialogVisible = true;
            //判断data是否为null，不为null，拆分数据，保存到表单
            if (data !== null) this.formPublish = {...data};
        },

        //点击确认按钮事件
        handleSubmit() {
            let that = this;
            //表单校验
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //获取uuid
                    let {uuid} = that.formPublish;
                    //判断uuid是否存在，存在修改同住人，不存在添加同住人
                    if (uuid) that.updateFamily();
                    else that.saveFamily();
                }
            });
        },

        //点击取消按钮事件
        handleCancel() {
            //关闭添加同住人弹框
            this.dialogVisible = false;
            //重置表单数据
            this.formPublish = {name: "", tel: "", idcard: "", relationship: ""}
            //调用上层方法刷新同住人
            this.$emit('getFamilyList');
        },

        //添加同住人方法
        saveFamily() {
            let that = this;
            //获取合同uuid
            const {uuid} = that.contractData;
            //拆分表单数据
            const data = {...that.formPublish};
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading(loadingOptions);
            //调用接口添加同住人
            saveFamily(uuid, data).then(res => {
                MessageSuccess("保存同住人成功");
                loading.close();
                //调用取消按钮事件关闭弹框
                that.handleCancel();
            }).catch(() => {
                MessageError("保存同住人失败");
                loading.close();
            });
        },
        //修改同住人方法
        updateFamily() {
            let that = this;
            //拆分表单数据
            const data = {...that.formPublish};
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading(loadingOptions);
            //调用接口修改同住人
            updateFamily(data).then(res => {
                MessageSuccess("保存同住人成功");
                loading.close();
                //调用取消按钮事件关闭弹框
                that.handleCancel();
            }).catch(() => {
                MessageError("保存同住人失败");
                loading.close();
            });
        },
    },
}
</script>

<style scoped>

</style>